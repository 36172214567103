import styled from "styled-components"
import { mainBlack, mainWhite } from "../constants"

export const MobileMenu = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: ${mainWhite};
  overflow: hidden;

  .menu-line {
    z-index: 2;
    position: relative;
    a {
      color: ${mainBlack};
    }

    &:hover {
      & div {
        opacity: 0.8;
        transition: 0.35s opacity linear;
      }
    }
  }

  .mobile-logo-wrapper {
    position: absolute;

    bottom: 0;
    width: 300%;
    left: -40%;
    opacity: 0.1;
  }
`

import { useInView } from "react-intersection-observer"

const useView = (rootMargin?: string): { wasInView: boolean; refItem: any } => {
  const [refItem, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: rootMargin || `0px`,
  })

  return {
    refItem,
    wasInView: inView,
  }
}

export default useView

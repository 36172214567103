import styled, { css } from "styled-components"

export const Container = styled.section`
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 32px;
  position: relative;
  width: auto;
  min-height: ${(props: { minHeight?: string; fluid?: boolean }): string => props.minHeight || `568px`};
  @media (min-width: 1024px) {
    max-width: 960px;
  }
  @media (min-width: 1216px) {
    max-width: 1152px;
  }
  @media (min-width: 1408px) {
    max-width: 1200px;
  }
  ${(props: { fluid?: boolean; minHeight?: string }): any =>
    props.fluid &&
    css`
      padding: 0;
      margin: 0;
      max-width: 100% !important;
    `}
`

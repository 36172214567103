/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from "react"
import { Box, Header, Text } from "grommet"
import { FormDown, FormUp } from "grommet-icons"

import { mainBlack, mainWhite } from "../../styles/constants"
import { DropDownMenu } from "../../styles/components/drop-down-menu"
import { SpringLink } from "./transition"
import { MobileHamburger } from "../../styles/components/mobile-hamburger"
import { MobileMenu } from "../../styles/components/mobile-menu"
import SlovaccLogo from "../../images/slovacc.svg"
import SlovaccLogoBlue from "../../images/slovaccBlue.svg"
import { PageComponentsProps } from "../../types/interface"
import FadeIn from "./fade-in"
import ScrollContext from "../../context/scroll-context"

const DesktopHeader: React.FunctionComponent = () => {
  const [open, setOpen] = useState<boolean>(false)
  const context = useContext(ScrollContext)

  return (
    <Header
      pad={{
        right: `120px`,
        left: `120px`,
        top: `.7rem`,
        bottom: `.7rem`,
      }}
      background="transparent"
      className="fixed header"
      style={{ zIndex: 9 }}
      width="100%"
    >
      <SpringLink aria-label="Slovacc" to="/" onClick={(): void => context.handleSetScrollTo(`home`)}>
        <SlovaccLogo width="100px" />
      </SpringLink>
      <Box direction="row" justify="between" gap="large">
        <Box className="cursor">
          <Text tag="span" size="medium">
            <SpringLink
              to="/"
              color={mainWhite}
              onClick={(): void => context.handleSetScrollTo(`home`)}
              className="anchor"
            >
              Domov
            </SpringLink>
          </Text>
        </Box>
        <Box className="cursor">
          <Text tag="span" size="medium">
            <SpringLink
              to="/"
              color={mainWhite}
              onClick={(): void => context.handleSetScrollTo(`references`)}
              className="anchor"
            >
              Odporúčania
            </SpringLink>
          </Text>
        </Box>
        <Box
          direction="row"
          className="cursor relative"
          onMouseEnter={(): void => setOpen(true)}
          onMouseLeave={(): void => setOpen(false)}
        >
          <Text color={mainWhite} size="medium">
            Služby
          </Text>
          {!open && <FormDown size="medium" color={mainWhite} />}
          {open && <FormUp size="medium" color={mainWhite} />}
          <DropDownMenu open={open}>
            <Box width="100%" direction="column" justify="center" className="drop" align="start">
              <Text size="medium" tag="div" margin={{ vertical: `8px` }}>
                <SpringLink color={mainWhite} to="/uctovnictvo">
                  Učtovníctvo a poradentsvo
                </SpringLink>
              </Text>
              <Text size="medium" tag="div" margin={{ vertical: `8px` }}>
                <SpringLink color={mainWhite} to="/mzdy-a-personalistika">
                  Mzdy a personalistika
                </SpringLink>
              </Text>
              <Text size="medium" tag="div" margin={{ vertical: `8px` }}>
                <SpringLink color={mainWhite} to="/danove-priznanie-uctovna-zavierka">
                  Daňové priznanie a účtovná závierka
                </SpringLink>
              </Text>
            </Box>
          </DropDownMenu>
        </Box>
        <Box className="cursor">
          <Text tag="span" size="medium">
            <SpringLink
              to="/"
              color={mainWhite}
              onClick={(): void => context.handleSetScrollTo(`pricing`)}
              className="anchor"
            >
              Cenník
            </SpringLink>
          </Text>
        </Box>
        <Box className="cursor">
          <Text tag="span" size="medium">
            <SpringLink
              to="/"
              color={mainWhite}
              onClick={(): void => context.handleSetScrollTo(`contact`)}
              className="anchor"
            >
              Kontakt
            </SpringLink>
          </Text>
        </Box>
      </Box>
    </Header>
  )
}

const MobileHeader: React.FC<{ desktop: boolean }> = ({ desktop }) => {
  const [open, setOpen] = useState<boolean>(false)
  const context = useContext(ScrollContext)

  const handleClose = (): void => setOpen(false)
  const handleMenuClose = (): void => document.getElementById(`toggle`)?.click()
  const handleScroll = (id: string): void => {
    handleMenuClose()
    handleClose()
    context.handleSetScrollTo(id)
  }

  return (
    <>
      {open && (
        <MobileMenu>
          <Box pad={desktop ? `20px` : `12px`} height="100vh" justify="center">
            <Text
              margin={{
                vertical: `12px`,
                horizontal: `0`,
              }}
              size="large"
              weight="bold"
              className="menu-line"
              color={mainBlack}
            >
              <FadeIn delay={100}>
                <SpringLink to="/" color={mainBlack} onClick={(): void => handleScroll(`home`)}>
                  Domov
                </SpringLink>
              </FadeIn>
            </Text>
            <Text
              margin={{
                vertical: `12px`,
                horizontal: `0`,
              }}
              size="large"
              weight="bold"
              className="menu-line"
              color={mainBlack}
            >
              <FadeIn delay={150}>
                <SpringLink to="/" color={mainBlack} onClick={(): void => handleScroll(`references`)}>
                  Odporúčania
                </SpringLink>
              </FadeIn>
            </Text>
            <Text
              margin={{
                vertical: `12px`,
                horizontal: `0`,
              }}
              size="large"
              weight="bold"
              className="menu-line"
              color={mainBlack}
            >
              <FadeIn delay={200}>
                <SpringLink to="/" color={mainBlack} onClick={(): void => handleScroll(`pricing`)}>
                  Cenník
                </SpringLink>
              </FadeIn>
            </Text>
            <Text
              margin={{
                vertical: `12px`,
                horizontal: `0`,
              }}
              size="large"
              weight="bold"
              className="menu-line"
              color={mainBlack}
            >
              <FadeIn delay={250}>
                <SpringLink to="/" color={mainBlack} onClick={(): void => handleScroll(`contact`)}>
                  Kontakt
                </SpringLink>
              </FadeIn>
            </Text>
            <Text
              margin={{
                vertical: `12px`,
                horizontal: `0`,
              }}
              size="large"
              weight="bold"
              className="menu-line"
              color={mainBlack}
              onClick={handleMenuClose}
            >
              <FadeIn delay={300}>
                <SpringLink to="/uctovnictvo" color={mainBlack}>
                  Učtovníctvo a poradentsvo
                </SpringLink>
              </FadeIn>
            </Text>
            <Text
              margin={{
                vertical: `12px`,
                horizontal: `0`,
              }}
              size="large"
              weight="bold"
              className="menu-line"
              color={mainBlack}
              onClick={handleMenuClose}
            >
              <FadeIn delay={350}>
                <SpringLink to="/mzdy-a-personalistika" color={mainBlack}>
                  Mzdy a personalistika
                </SpringLink>
              </FadeIn>
            </Text>
            <Text
              margin={{
                vertical: `12px`,
                horizontal: `0`,
              }}
              size="large"
              weight="bold"
              className="menu-line"
              onClick={handleMenuClose}
            >
              <FadeIn delay={400}>
                <SpringLink to="/danove-priznanie-uctovna-zavierka" color={mainBlack}>
                  Daňové priznanie a účtovná závierka
                </SpringLink>
              </FadeIn>
            </Text>
          </Box>
          <FadeIn delay={500}>
            <SlovaccLogoBlue className="mobile-logo-wrapper" />
          </FadeIn>
        </MobileMenu>
      )}
      <Header
        pad={{
          right: `32px`,
          left: `32px`,
          top: `16px`,
          bottom: `16px`,
        }}
        background={mainWhite}
        className="fixed"
        style={{ zIndex: 4, top: 0, left: 0 }}
        width="100%"
      >
        <SpringLink
          aria-label="Slovacc"
          to="/"
          className="mobile-logo-menu"
          onClick={(): void => context.handleSetScrollTo(`home`)}
        >
          <SlovaccLogoBlue width="100px" />
        </SpringLink>
        <Box direction="row" justify="end" gap="large">
          <MobileHamburger onClick={(): void => setOpen(!open)}>
            <Box id="menuToggle">
              <input id="toggle" type="checkbox" />
              <span></span>
              <span></span>
              <span></span>

              <Box tag="ul" id="menu" />
            </Box>
          </MobileHamburger>
        </Box>
      </Header>
    </>
  )
}

const HeaderLayout: React.FC<PageComponentsProps> = ({ size }) => {
  const desktop = size !== `small`
  return desktop ? <DesktopHeader /> : <MobileHeader desktop={desktop} />
}

export default HeaderLayout

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react"
import { animated, Spring } from "react-spring/renderprops"
import TransitionLink, { TransitionState } from "gatsby-plugin-transition-link"

const Transition: React.FC<{ className?: string }> = ({ children, className }) => (
  <TransitionState>
    {({ transitionStatus, exit, entry }: any): JSX.Element => {
      const mount = [`entering`, `entered`].includes(transitionStatus)
      const seconds = mount ? entry.length : exit.length

      return (
        <Spring
          to={{
            opacity: mount ? 1 : 0,
          }}
          config={{
            duration: seconds * 500,
          }}
        >
          {(props: any): JSX.Element => (
            <animated.div className={className} style={{ ...props }}>
              {children}
            </animated.div>
          )}
        </Spring>
      )
    }}
  </TransitionState>
)

const SpringLink: React.FC<{ to: string; className?: string; ref?: any; onClick?: () => void; color?: string }> = ({
  to,
  children,
  color,
  ...props
}) => (
  <TransitionLink style={{ color }} to={to} exit={{ length: 1 }} entry={{ length: 1, delay: 1 }} {...props}>
    {children}
  </TransitionLink>
)

export { Transition, SpringLink }

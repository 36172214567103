/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react"
import { animated, useSpring } from "react-spring"

interface FadeInProps {
  delay?: number
  className?: string
}

const FadeIn: React.FC<FadeInProps> = ({ children, delay = 0, className }) => {
  const props = useSpring({
    config: { tension: 900, friction: 350 },
    opacity: 1,
    delay,
    animationTimingFunction: `cubic-bezier(0, 1.15, 0.99, 1)`,
    x: 0,
    from: { opacity: 0, x: 50 },
  })
  return (
    <animated.div
      className={className}
      style={{ ...props, zIndex: 1, transform: props.x.interpolate((x: number) => `translate3d(0, ${x}px,0)`) }}
    >
      {children}
    </animated.div>
  )
}

export default FadeIn

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react"
import { Box, Text } from "grommet"
import { mainBlue, mainWhite } from "../../styles/constants"
import styled from "styled-components"
import { Container } from "../../styles/components/container"
import { SpringLink } from "./transition"
import useView from "../../hooks/view"
import SlovaccLogo from "../../images/slovacc.svg"
import { PageComponentsProps } from "../../types/interface"

const FooterComponentFluid = styled.footer`
  width: 100%;
  margin-top: 100px;
  background-color: ${mainBlue};
  overflow: hidden;

  .footer-logo {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    opacity: 0.1;
    width: 150%;
    z-index: 1;
  }

  & a {
    transition: 0.15s ease-in opacity;

    &:hover {
      transition: 0.15s ease-in opacity;
      opacity: 0.5;
    }
  }
`

const FooterLayout: React.FC<PageComponentsProps> = ({ size }) => {
  const { refItem, wasInView } = useView()
  const desktop = size !== `small`

  return (
    <FooterComponentFluid ref={refItem}>
      <Container minHeight={desktop ? `auto` : `100vh`}>
        <Box height={desktop ? `40vh` : `auto`} className="relative" width="100%">
          {wasInView && (
            <>
              <Box
                style={{ zIndex: 2 }}
                direction={desktop ? `row` : `column`}
                justify="start"
                height="100%"
                align="center"
              >
                <Box pad={{ vertical: desktop ? `16px` : `8px`, right: `32px` }} width={desktop ? `auto` : `100%`}>
                  <Text
                    margin={{
                      vertical: `20px`,
                      horizontal: `0`,
                    }}
                    size="large"
                    weight="bold"
                    color={mainWhite}
                  >
                    Informácie
                  </Text>
                  <Box direction="row">
                    <Box>
                      <Text
                        margin={{
                          left: `16px`,
                          bottom: `8px`,
                        }}
                        size="medium"
                        color={mainWhite}
                      >
                        SlovAcc, s. r. o.
                      </Text>
                      <Text
                        margin={{
                          left: `16px`,
                          bottom: `8px`,
                        }}
                        size="medium"
                        color={mainWhite}
                      >
                        Ulica pri Čiernej vode 5446/44
                      </Text>
                      <Text
                        margin={{
                          left: `16px`,
                          bottom: `8px`,
                        }}
                        size="medium"
                        color={mainWhite}
                      >
                        903 01 Senec
                      </Text>
                      <Text
                        margin={{
                          left: `16px`,
                          bottom: `8px`,
                        }}
                        size="medium"
                        color={mainWhite}
                      >
                        IČO: 51825724
                      </Text>
                    </Box>
                  </Box>
                </Box>
                <Box
                  pad={{ vertical: desktop ? `16px` : `8px`, left: desktop ? `32px` : `0` }}
                  width={desktop ? `auto` : `100%`}
                >
                  <Text
                    margin={{
                      vertical: `20px`,
                      horizontal: `0`,
                    }}
                    size="large"
                    weight="bold"
                    color={mainWhite}
                  >
                    Stránky
                  </Text>
                  <Box direction={desktop ? `row` : `column`}>
                    <Box>
                      <Text
                        margin={{
                          left: `16px`,
                          bottom: `8px`,
                        }}
                        size="medium"
                      >
                        <SpringLink color={mainWhite} to="/">
                          Domov
                        </SpringLink>
                      </Text>
                      <Text
                        margin={{
                          left: `16px`,
                          bottom: `8px`,
                        }}
                        size="medium"
                      >
                        <SpringLink color={mainWhite} to="/">
                          O nás
                        </SpringLink>
                      </Text>
                      <Text
                        margin={{
                          left: `16px`,
                          bottom: `8px`,
                        }}
                        size="medium"
                      >
                        <SpringLink color={mainWhite} to="/">
                          Cenník
                        </SpringLink>
                      </Text>
                      <Text
                        margin={{
                          left: `16px`,
                          bottom: `8px`,
                        }}
                        size="medium"
                      >
                        <SpringLink color={mainWhite} to="/">
                          Kontakt
                        </SpringLink>
                      </Text>
                    </Box>
                    {desktop && (
                      <Box>
                        <Text
                          size="medium"
                          margin={{
                            left: `48px`,
                            bottom: `8px`,
                          }}
                        >
                          <SpringLink color={mainWhite} to="/uctovnictvo">
                            Učtovníctvo a poradentsvo
                          </SpringLink>
                        </Text>
                        <Text
                          size="medium"
                          margin={{
                            left: `48px`,
                            bottom: `8px`,
                          }}
                        >
                          <SpringLink color={mainWhite} to="/mzdy-a-personalistika">
                            Mzdy a personalistika
                          </SpringLink>
                        </Text>
                        <Text
                          size="medium"
                          margin={{
                            left: `48px`,
                            bottom: `8px`,
                          }}
                        >
                          <SpringLink color={mainWhite} to="/danove-priznanie-uctovna-zavierka">
                            Daňové priznanie a účtovná závierka
                          </SpringLink>
                        </Text>
                      </Box>
                    )}
                  </Box>
                </Box>
                {!desktop && (
                  <Box
                    pad={{ vertical: desktop ? `16px` : `8px`, left: desktop ? `32px` : `0` }}
                    width={desktop ? `auto` : `100%`}
                  >
                    <Text
                      margin={{
                        vertical: `20px`,
                        horizontal: `0`,
                      }}
                      size="large"
                      weight="bold"
                      color={mainWhite}
                    >
                      Služby
                    </Text>
                    <Box direction={desktop ? `row` : `column`}>
                      <Box>
                        <Text
                          size="medium"
                          margin={{
                            left: `16px`,
                            bottom: `8px`,
                          }}
                        >
                          <SpringLink color={mainWhite} to="/uctovnictvo">
                            Učtovníctvo a poradentsvo
                          </SpringLink>
                        </Text>
                        <Text
                          size="medium"
                          margin={{
                            left: `16px`,
                            bottom: `8px`,
                          }}
                        >
                          <SpringLink color={mainWhite} to="/mzdy-a-personalistika">
                            Mzdy a personalistika
                          </SpringLink>
                        </Text>
                        <Text
                          size="medium"
                          margin={{
                            left: `16px`,
                            bottom: `8px`,
                          }}
                        >
                          <SpringLink color={mainWhite} to="/danove-priznanie-uctovna-zavierka">
                            Daňové priznanie a účtovná závierka
                          </SpringLink>
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box
                direction={desktop ? `row` : `column`}
                pad={{ top: desktop ? `0` : `16px`, bottom: `32px` }}
                justify="between"
                width="100%"
                style={{ zIndex: 2 }}
              >
                <Text size="small" color={mainWhite}>
                  © SlovAcc, s.r.o. | Účtovníctvo pre každého |{` `}
                  <b>
                    <a href="/pouzivanie-suborov-cookies" target="_blank">
                      Používanie súborov cookies
                    </a>
                  </b>
                  {` `}|{` `}
                  <b>
                    <a href="/zasady-ochrany-osobnych-udajov" target="_blank">
                      Zásady ochrany osobných údajov
                    </a>
                  </b>
                </Text>
                <Text margin={{ top: desktop ? `0` : `16px` }} size="small" color={mainWhite}>
                  Created by{` `}
                  <a style={{ fontWeight: `bold` }} href="https://www.clent.io" target="_blank">
                    Clentio
                  </a>
                </Text>
              </Box>
            </>
          )}
          <SlovaccLogo width="100%" className="footer-logo" />
        </Box>
      </Container>
    </FooterComponentFluid>
  )
}

export default FooterLayout

import styled from "styled-components"
import { mainBlue } from "../constants"

export const DropDownMenu = styled.div`
  width: 380px;
  min-height: ${(props: { open: boolean }): string => (props.open ? `auto` : `0`)};
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  z-index: -1;

  .drop {
    position: relative;
    top: 54px;
    padding: 24px 0px 24px 0;
    background-color: ${mainBlue};
    display: ${(props: { open: boolean }): string => (props.open ? `flex` : `none`)};

    .caret {
      position: absolute;
      right: -12px;
      top: -30px;
    }

    & a {
      padding: 24px 20px;
      transition: 0.15s ease-in opacity;

      &:hover {
        transition: 0.15s ease-in opacity;
        opacity: 0.5;
      }
    }
  }
`
